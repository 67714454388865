import React from "react";
import qs from "query-string";
import clipboardCopy from "copy-to-clipboard";
import moment from "moment-timezone";
import cx from "classnames";

import { painLocationOptions } from "./programs";
import { meetingStatusTypes } from "./consults";
import * as routes from "./routes";

import Image from "../components/image/Image";
import { getMyActiveSubscription } from "./subscriptions";

export const DATE_FORMAT_STRING = "M/D/YYYY";
export const TIME_FORMAT_STRING = "h:mm A";
export const TEST_EMAIL_DOMAINS = ["telespine.com", "orbittelehealth.com", "topnotchltd.com", "test.com", "dallas.com"];
export const dateTimeFormatString = `${DATE_FORMAT_STRING} @ ${TIME_FORMAT_STRING}`;

export const appWrapperClassName = "ts-app";
export const patientAppClassName = "ts-patient-app";

export const SEARCH_DELAY = 500;
export const SUBSCRIPTION_GRACE_PERIOD = 14;

export const DEFAULT_PROVIDER_PAGE_SIZE = 100;

export const TEST_SUBDOMAINS = ["localhost", "development.orbittelehealth.com", "staging.orbittelehealth.com"];

export const MONTHS = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
];

export const CARD_BRANDS = [
    { name: "amex", icon: "ic_amex.png" },
    { name: "American Express", icon: "ic_amex.png" },
    { name: "diners", icon: "ic_diners.png" },
    { name: "Diners Club", icon: "ic_diners.png" },
    { name: "discover", icon: "ic_discover.png" },
    { name: "Discover", icon: "ic_discover.png" },
    { name: "jcb", icon: "ic_jcb.png" },
    { name: "JCB", icon: "ic_jcb.png" },
    { name: "mastercard", icon: "ic_mastercard.png" },
    { name: "MasterCard", icon: "ic_mastercard.png" },
    { name: "unionpay", icon: "ic_unionpay.png" },
    { name: "UnionPay", icon: "ic_unionpay.png" },
    { name: "visa", icon: "ic_visa.png" },
    { name: "Visa", icon: "ic_visa.png" },
    { name: "unknown", icon: "ic_card_default.png" },
    { name: "Unknown", icon: "ic_card_default.png" },
];

const IGNORED_FILTER_FIELDS = ["page", "expand", "fields", "ordering", "show_all_brandings", "branding__display_name"];

export const ROLES_LIST = [
    { value: 40, label: "Owner" },
    { value: 30, label: "Admin" },
    { value: 40, label: "Provider" },
];

export const PROVIDER_TYPES = [
    { value: "LMT", label: "LMT" },
    { value: "PT", label: "PT" },
    { value: "MPT", label: "MPT" },
    { value: "DPT", label: "DPT" },
    { value: "SLP", label: "SLP" },
    { value: "MT", label: "MT" },
    { value: "OT", label: "OT" },
    { value: "RN", label: "RN" },
    { value: "CLT", label: "CLT" },
    { value: "PTA", label: "PTA" },
];

export const COUNTRY_LIST = [
    { label: "United States", value: "US" },
    { label: "Canada", value: "CA" },
    { label: "Afghanistan", value: "AF" },
    { label: "Åland Islands", value: "AX" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "AndorrA", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, The Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Cote D'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, Democratic People'S Republic of", value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People'S Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "Netherlands Antilles", value: "AN" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestinian Territory, Occupied", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "RWANDA", value: "RW" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia and Montenegro", value: "CS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Surilabel", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
];

export const STATES_LIST = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
];

export const CANADIAN_PROVINCES = [
    { value: "AB", label: "Alberta" },
    { value: "BC", label: "British Columbia" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
];

export const COUNTRIES_SEARCH_LIST = [
    { value: "United Kingdom", label: "United Kingdom of Great Britain and Northern Ireland" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "France", label: "France" },
    { value: "Sweden", label: "Sweden" },
];

export const EXPERTISE_OPTIONS = [
    { value: "", label: "Skill level" },
    { value: "novice", label: "Novice" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advanced", label: "Advanced" },
    { value: "expert", label: "Expert" },
];

const PROVIDER_COPY = [
    { label: "coach_notes", title: "Coach Notes", provider_title: "Clinical Notes" },
    { label: "coach", title: "coach", provider_title: "provider" },
    { label: "coaches", title: "coaches", provider_title: "providers" },
    { label: "appointment", title: "appointment", provider_title: "consult" },
    { label: "program", title: "program", provider_title: "care plan" },
    { label: "schedule_an_appointment", title: "Schedule an Appointment", provider_title: "Schedule a consult" },
    { label: "all_coaches", title: "All coaches", provider_title: "All providers" },
    { label: "coaching_dropdown", title: "Coaching", provider_title: "Connect" },
    { label: "chat_with_coach", title: "Chat with a Health Coach", provider_title: "Chat with your provider" },
    { label: "session", title: "session", provider_title: "plan" },
    { label: "reschedule_appointment", title: "Reschedule Appointment", provider_title: "Reschedule consult" },
    {
        label: "connect_with_coach",
        title:
            "Connect with your very own expert health coach now to start receiving personalized recommendations and guidance",
        provider_title: "Connect with your provider to start receiving personalized recommendations and guidance",
    },
    {
        label: "chat_empty_state_message",
        title: "You don't have any messages yet. Try sending one to your coach now!",
        provider_title: "You don't have any messages from your provider yet. Try sending one now!",
    },
    {
        label: "chat_out_of_office",
        title:
            "It is now outside of business hours and all of our coaches have gone home for the day. You can send a message and someone will respond next time we're open.",
        provider_title:
            "It is now outside of business hours. You can send a message now and we will respond as soon as possible.",
    },
    {
        label: "appointment_helper",
        title: "You will receive an email confirming your 20-30 minute coaching appointment.",
        provider_title: "You will receive an email confirming your consult.",
    },
    {
        label: "appointment_scheduled",
        title: "Your apppointment has been confirmed!",
        provider_title: "Your consult has been confirmed!",
    },
    {
        label: "subscription_helper",
        title:
            "Coaching consults, recommendations and program updates require an active monthly subscription. By confirming the appointment below, you are initiating a recurring subscription that will renew at the same price on a monthly basis. Your subscription will unlock unlimited coaching interactions and program updates. You can cancel your subscription at anytime.",
        provider_title:
            "Provider consults, recommendations and care plan updates require an active monthly subscription. By confirming the appointment below, you are initiating a recurring subscription that will renew at the same price on a monthly basis. Your subscription will unlock unlimited provider interactions and care plan updates. You can cancel your subscription at anytime.",
    },
];

const holidayDates = [
    { date: 31, month: 12 },
    { date: 24, month: 12 },
];

export function capitalize(string) {
    if (string && string.length > 0) {
        const firstChar = string.charAt(0).toUpperCase();
        const rest = string.slice(-(string.length - 1));

        return `${firstChar}${rest}`;
    }

    return string;
}

export function getV2Route(directory, options = {}) {
    const { noHash = false } = options;
    const {
        // environment vars to allow local development
        NODE_ENV,
        REACT_APP_DJANGO_SERVER_PORT,
    } = process.env;
    const {
        location: { origin: windowOrigin },
    } = window;

    const origin =
        NODE_ENV === "development" && REACT_APP_DJANGO_SERVER_PORT !== undefined
            ? `http://localhost:${REACT_APP_DJANGO_SERVER_PORT}`
            : windowOrigin;

    const redirectPath = `${origin}${noHash ? "" : "/#"}${directory}`;

    return redirectPath;
}

/**
 *
 * @param {*} group_parent
 * @returns Converts new backend list of pain locations to the old format used by the front end
 */
export function convertPainLocationOptions(group_parent) {
    const pain_locations = [];

    if (group_parent.pl_hip) pain_locations.push("hip");
    if (group_parent.pl_knee) pain_locations.push("knee");
    if (group_parent.pl_neck) pain_locations.push("neck");
    if (group_parent.pl_lower_back) pain_locations.push("lower_back");
    if (group_parent.pl_upper_back) pain_locations.push("upper_back");
    if (group_parent.pl_shoulder) pain_locations.push("shoulder");
    if (group_parent.pl_womens_health) pain_locations.push("womens_health");
    if (group_parent.pl_other) pain_locations.push("other");
    if (group_parent.pl_unknown) pain_locations.push("unknown");

    return pain_locations;
}

export function getReadablePainLocation(location, field) {
    const locationObj = painLocationOptions.find((l) => l.backendId === location);

    if (!locationObj) {
        return "[unknown location]";
    }

    if (!locationObj[field]) {
        return "[invalid location field]";
    }

    return locationObj[field];
}

export function getProviderCopy(branding, label) {
    const matchingLabel = PROVIDER_COPY.find((copy) => copy.label === label);

    return branding.is_organization ? matchingLabel.provider_title : matchingLabel.title;
}

export function getMeetingStatusLabel(meeting) {
    if (meeting.status === "cancelled_by_patient")
        return `Cancelled by ${meeting.patient && meeting.patient.first_name}`;
    if (meeting.status === "cancelled_by_coach") return `Cancelled by ${meeting.coach && meeting.coach.first_name}`;

    return meetingStatusTypes[meeting.status].label;
}

export function getInitialSurvey(surveys) {
    if (!surveys || !surveys.length) {
        return null;
    }

    // surveys are returned in ascending order of creation and this method will
    // attempt to find an initial survey from the list and return it. it will return
    // null if none exist.
    const latestInitial = surveys.find((s) => Object.prototype.hasOwnProperty.call(s, "red_flag"));

    return latestInitial || null;
}

export async function loadExternalScript(id, src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");

        script.id = id;
        script.src = src;

        script.addEventListener("load", function () {
            resolve();
        });

        script.addEventListener("error", function (e) {
            reject(e);
        });

        document.body.appendChild(script);
    });
}

export async function unloadExternalScript(id) {
    try {
        const element = document.getElementById(id);
        element.remove();
    } catch (e) {
        console.log("Error unloading external script:", e);
    }
}

export async function unloadRecaptchaBadge() {
    const nodeBadge = document.querySelector(".grecaptcha-badge");
    if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
    }
}

export async function executeRecaptcha(action) {
    // NOTE: action names may only contain alphanumeric characters and slashes
    return new Promise((resolve, reject) => {
        try {
            const { grecaptcha } = window;
            const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

            grecaptcha.ready(() => {
                grecaptcha
                    .execute(REACT_APP_RECAPTCHA_SITE_KEY, { action })
                    .then((token) => {
                        resolve(token);
                    })
                    .catch((error) => reject(error));
            });
        } catch (e) {
            reject(e);
        }
    });
}

export function getVisitorType(me) {
    const { email } = me;
    return email && TEST_EMAIL_DOMAINS.includes(email.split("@")[1]) ? "tester" : "customer";
}

export function getPainLocationFromMe(me) {
    const { assigned_program } = me || {};

    if (!assigned_program || !assigned_program.pain_location) {
        return false;
    }

    return getReadablePainLocation(assigned_program.pain_location, "lowerCase");
}

export function formatDate(date) {
    if (date) {
        const dateObj = new Date(date);

        return new Intl.DateTimeFormat("en-US").format(dateObj);
    } else {
        return "";
    }
}

export function formatTime(date) {
    if (date) {
        const dateObj = new Date(date);
        return new Intl.DateTimeFormat("en-US", { timeStyle: "short" }).format(dateObj);
    } else {
        return "";
    }
}

export function formatDay(date) {
    if (date) {
        const dateObj = new Date(date);
        return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(dateObj);
    } else {
        return "";
    }
}

export function getPublicImage(filename) {
    const { PUBLIC_URL } = process.env;

    return `${PUBLIC_URL}/${filename}`;
}

export function getLoginUrl(branding = {}) {
    const { idp_signin_url } = branding;

    if (idp_signin_url && idp_signin_url.length) {
        return idp_signin_url;
    }

    const appDomain = window.location.host;
    const defaultLoginRoute = routes.LOGIN_ROUTE;

    return `https://${appDomain}${defaultLoginRoute}`;
}

export function redirectToLogin(options = {}) {
    const { inactive } = options;
    let newLocation = routes.LOGIN_ROUTE;

    if (inactive === true) {
        newLocation += `?${qs.stringify({ inactive: true })}`;
    }

    window.location.assign(newLocation);
}

export function redirectToV2(path = routes.V2_HOME_ROUTE, options = {}) {
    const redirectPath = getV2Route(path, options);

    window.location.assign(redirectPath);
}

export function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function copyToClipboard(text) {
    // we need to invoke this method twice because of a bug in older browsers
    clipboardCopy(text);
    clipboardCopy(text);
}

export function isWorkingHour(time) {
    // Mon-Fri 8am - 5:45pm Mountain
    const denverTime = moment(time).tz("America/Denver");
    const denverDay = denverTime.day();
    const openTime = moment.tz(`${denverTime.format("YYYY-MM-DD")}T08:00:00`, "America/Denver");
    const closeTime = moment.tz(`${denverTime.format("YYYY-MM-DD")}T17:45:00`, "America/Denver");
    let isOpen = denverTime.isBetween(openTime, closeTime) && denverDay >= 1 && denverDay <= 5;

    if (isOpen) {
        const month = denverTime.month();
        const date = denverTime.date();

        holidayDates.forEach((holiday) => {
            if (month === holiday.month - 1 && date === holiday.date) isOpen = false;
        });
    }

    return isOpen;
}

export function checkUpcomingScheduledMeeting(meeting) {
    const now = moment(new Date());
    const tenMinutesAfterStart = moment(meeting.start_time).add(10, "minutes");

    if (
        (meeting.status === "upcoming" || meeting.status === "in_progress") &&
        now <= tenMinutesAfterStart &&
        !meeting.on_demand
    ) {
        return meeting;
    }

    return false;
}
export function hideConnectWithCoach(meeting) {
    const now = moment(new Date());
    const tenMinutesAfterStart = moment(meeting.start_time).add(10, "minutes");
    const twominutesBeforeStart = moment(meeting.start_time).subtract(2, "minutes");

    if (meeting && meeting.status === "upcoming" && (now >= tenMinutesAfterStart || now >= twominutesBeforeStart)) {
        return true;
    }

    return false;
}

export function checkOnDemandMeeting(meeting) {
    return meeting.on_demand;
}

export function checkCanJoinMeeting(meeting) {
    const now = moment(new Date());
    const twoMinutesBeforeStart = moment(meeting.start_time).subtract(2, "minutes");
    const tenMinutesAfterStart = moment(meeting.start_time).add(10, "minutes");

    if (
        (meeting.status === "upcoming" || meeting.status === "in_progress") &&
        twoMinutesBeforeStart <= now &&
        now <= tenMinutesAfterStart
    ) {
        return meeting;
    }

    return false;
}

export function getSystemTimezone() {
    return moment.tz.guess();
}

export function getAvailableDates(allTimes, selectedCoaches) {
    return Array.from(
        new Set(
            allTimes
                .filter((availableTime) => {
                    return selectedCoaches.find((coach) => coach.id === availableTime.coach.id);
                })
                .sort((a, b) => moment(a.start_time).valueOf() - moment(b.start_time).valueOf())
                .map((availableTime) => moment(availableTime.start_time).startOf("day").format())
        )
    );
}

export function getAvailableTimes(allTimes, selectedCoaches, selectedDate) {
    const selectedCoachesIds = [...new Set(selectedCoaches.map((coach) => coach.id))];
    return Array.from(
        new Set(
            allTimes
                .filter((availableTime) => {
                    const availableTimeDay = moment(availableTime.start_time).startOf("day");
                    const selectedDateDay = moment(selectedDate).startOf("day");

                    return (
                        selectedDateDay.format() === availableTimeDay.format() &&
                        selectedCoachesIds.includes(availableTime.coach.id)
                    );
                })
                .sort((a, b) => moment(a.start_time).valueOf() - moment(b.start_time).valueOf())
                .map((availableTime) => moment(availableTime.start_time).format("h:mm A"))
        )
    );
}

export function getAgeFromBirthday(birthday) {
    const momentBday = moment(birthday);

    return moment().diff(momentBday, "years");
}

export function getLocalTimeFromTimezone(timezone) {
    return timezone ? moment().tz(timezone).format("h:mm a") : "-";
}

/**
    exq stands for Expandable Query and constructs query string values to use for expandable
    API requests. It can be invoked normally with a string or as a tag function. Individual
    items should be separated by any whitespace character.
 */
export function exq(...input) {
    if (!input || !input.length) {
        return "";
    }

    const combined = input.map((i) => {
        const first = Array.isArray(i) ? i[0] : i; // select arguments based on type
        const replaced = first.replace(/,/g, " ");
        const split = replaced.split(/\s/); // split by whitespace characters
        const clean = split.map((i) => i.trim()).filter((s) => s.length > 0); // trim and filter
        const output = clean.join(","); // create output array

        return output;
    });

    return combined.join(",");
}

export function getGreeting() {
    const hour = moment().hour();

    if (hour < 12) {
        return "Good morning";
    } else if (hour >= 12 && hour < 18) {
        return "Good afternoon";
    } else {
        return "Good evening";
    }
}

export function getPatientDisplayName(patient) {
    const { first_name = "", last_name = "", preferred_name = "" } = patient || {};
    const fullName = `${first_name} ${last_name}`;

    return preferred_name && preferred_name.length > 0 ? `${preferred_name} (${fullName})` : fullName;
}

export function getErrorMessage(error, fallback) {
    return (error && error.response && error.response.data) || fallback;
}

export function getTypeErrorMessage(error, fallback) {
    return (
        (error && error.response && error.response.data && error.response.data.type && error.response.data.type[0]) ||
        fallback
    );
}

export function isEnterKey(event) {
    const validCodes = ["Enter", "NumpadEnter"];

    return validCodes.includes(event.key || event.code);
}

export function getUserProfileImage(user, { className, ...props }) {
    const { profile_image } = user || {};

    if (profile_image && profile_image.length > 0) {
        return <Image src={profile_image} alt="profile" className={className} {...props} />;
    }

    return <i className={cx("fas fa-user-circle", className)} {...props} />;
}

export function getCurrentProgram(assignedPrograms = []) {
    const sorted = [...assignedPrograms].sort((a, b) => moment(a.created).valueOf() - moment(b.created).valueOf());

    return sorted && sorted.length > 0 && sorted[sorted.length - 1];
}

export function storageAvailable(type) {
    var storage;

    try {
        storage = window[type];
        var x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}

/**
 *
 * @param {*} cards
 * @param {*} me
 * @returns Returns a valid cards to show the user
 */
export function processActionCards(cards, subscriptions) {
    const activeSubscription = getMyActiveSubscription(subscriptions);
    const proccessedCards =
        cards &&
        cards.length > 0 &&
        cards.filter((card) => {
            if (card.type === "account-upgrade") {
                if (activeSubscription) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });

    return proccessedCards;
}

/**
 *
 * @param {*} params
 * @param {*} searchValue
 * @returns Number of applied filters on patients data page
 */
export function getFilterCount(params, searchValue) {
    Object.keys(params).forEach(
        (key) => (params[key] === null || params[key] === false || params[key] === "") && delete params[key]
    );

    const appliedFilters = Object.keys(params).filter((key) => params[key] !== null);
    let filterCount = appliedFilters.length;

    // Dont count filters that arent controlled by user or search
    Object.keys(params).forEach((key) => IGNORED_FILTER_FIELDS.includes(key) && filterCount--);
    // if (me.branding && !me.branding.is_organization && formValues[showAllBrandingsId] === false) filterCount -= 1;
    if (searchValue && searchValue.length > 0) filterCount -= 1;

    return filterCount;
}

/**
 *
 * @param {*} me
 * @param {*} patients
 * @returns Determines if user is blocked from inviting additional patients
 */
export function preventUserInvite(me, patients) {
    return false;

    // This logic will be re-written when MFR Virtual clinic is introduced
    // const preventInvite =
    //     (patients >= PATIENT_INVITE_LIMIT &&
    //         me.branding &&
    //         me.branding.is_organization &&
    //         me.branding.stripe_subscription_status &&
    //         INCOMPLETE_STRIPE_STATUSES.includes(me.branding.stripe_subscription_status)) ||
    //     (patients >= PATIENT_INVITE_LIMIT && !me.branding.stripe_subscription_status && me.branding.is_organization) ||
    //     (patients >= PATIENT_INVITE_LIMIT &&
    //         me.branding.stripe_subscription_status &&
    //         INCOMPLETE_STRIPE_STATUSES.includes(me.branding.stripe_subscription_status) &&
    //         me.branding.is_organization);

    // return preventInvite || false;
}

export function IsOnboardingFlow() {
    const isOnboarding =
        window.location.pathname.startsWith(routes.ONBOARDING_SURVEY_ROUTE) ||
        window.location.pathname.startsWith(routes.ABANDON_SURVEY_ROUTE) ||
        window.location.pathname.startsWith(routes.FINAL_SURVEY_ROUTE) ||
        window.location.pathname.startsWith(routes.TELESPINE_INTRODUCTION_SURVEY_ROUTE) ||
        window.location.pathname.startsWith(routes.TIME_BASED_SURVEY_ROUTE);

    return isOnboarding;
}

/**
 *
 * @param {*} phone
 * @returns Returns a formatted phone number with hyphens
 */
export function formatPhoneNumber(phone) {
    let trimmedPhone = phone.slice(2, 12);
    const formattedPhone =
        "(" + trimmedPhone.slice(0, 3) + ") " + trimmedPhone.slice(3, 6) + "-" + trimmedPhone.slice(6);

    return formattedPhone;
}

/**
 *
 * @param {*} group
 * @returns Returns true if group belongs to MFR Parent group
 */
export function isMFRGroup(group) {
    return group && group.parent && group.parent.branding && group.parent.branding.name === "MFR" ? true : false;
}

/**
 *
 * @param {*} addressComponents
 * @returns Converts google address components to an address object
 */
export function parseGoogleAddressComponents(addressComponents) {
    return addressComponents.reduce((seed, { long_name, types }) => {
        types.forEach((t) => (seed[t] = long_name));

        return seed;
    }, {});
}

/**
 *
 * @param {*} role_level
 * @returns Converts role level to a label
 */
export function getRoleLabel(role_level) {
    switch (role_level) {
        case 40:
            return "Owner";
        case 30:
            return "Admin";
        case 20:
            return "Provider";
        default:
            return "";
    }
}
